<template>
  <div>
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-auto my-auto">
            <h5 class="fw-bold mb-0">Treatment Booking #{{ booking.id }}</h5>
          </div>
          <div class="col-auto my-auto">
            <a
              @click="viewBooking(booking.booking.id)"
              class="mb-0 cursor-pointer"
              v-if="booking.booking"
            >
              Attached to Booking #{{ booking.booking.id }}</a
            >
          </div>
          <div class="col-auto my-auto ms-auto">
            <button class="btn btn-sm btn-outline-success" @click="close">
              <i class="far fa-times"></i>
            </button>
          </div>
        </div>

            <form @submit.prevent>
              <div class="row">
                <div class="col-md-4 my-auto">
                  <label for="">Choose treatment booking type</label>
                  <select v-model="form.bundle" class="form-control">
                    <option value="" disabled>Select type of booking</option>
                    <option value="no_bundle">
                      No Bundle - Items priced individually
                    </option>
                    <option
                      v-if="booking && booking.booking.retreat == 'uk'"
                      value="bundle"
                    >
                      Bundle - £790 for 10 treatment credits
                    </option>
                    <option
                      v-if="booking && booking.booking.retreat == 'malta'"
                      value="bundle"
                    >
                      Bundle - €850 for 10 treatment credits
                    </option>
                  </select>
                </div>

                <div
                  class="col-auto ms-auto my-auto"
                  v-if="form.bundle == 'bundle'"
                >
                  <div class="card border-0 bg-light shadow-none">
                    <div class="card-body">
                      <p class="mb-0">Credits remaining</p>
                      <h4 class="fw-bold text-success mb-0">
                        {{ form.credits }}
                      </h4>
                    </div>
                  </div>
                </div>

                <div class="col-auto ms-auto my-auto" v-else>
                  <div class="card border-0 bg-light shadow-none">
                    <div class="card-body">
                      <p class="mb-0">Total</p>
                      <h4 class="fw-bold text-success mb-0">
                        {{ booking && booking.booking.retreat == "malta" ? "€" : "£" }}{{ form.total.toFixed(2) }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <!--  -->
              <div class="row mt-4" v-if="booking">
                <div class="col-md-4">
                  <div class="card border-0 bg-light shadow-sm">
                    <div class="card-header bg-light border-0">
                      <h5 class="fw-bold text-success">Select treatments</h5>
                    </div>
                    <div class="card-body pt-0">
                      <ul class="list-group list-group-flush cursor-pointer">
                        <li
                          class="list-group-item"
                          v-for="t in availableTreatments"
                          :key="t.id"
                          @click="addTreatment(t)"
                        >
                          <div class="row">
                            <div class="col-2 my-auto">
                              <img :src="t.image" :alt="t.title" width="100%" />
                            </div>
                            <div class="col my-auto">
                              <h6 class="fw-bold mb-0">
                                {{ t.title }}
                              </h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!--  -->

                <div class="col">
                  <div class="card border-0 bg-light shadow-sm">
                    <div class="card-body">
                      <div
                        v-if="form.treatments.length == 0"
                        class="text-center"
                      >
                        <i class="far fa-syringe fa-3x text-success"></i>
                        <p class="fw-bold">
                          The treatments you choose will be listed here, confirm
                          your treatment booking below
                        </p>
                      </div>
                      <div v-else>
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item"
                            v-for="(t, key) in form.treatments"
                            :key="key"
                          >
                            <div class="row mb-2">
                              <div class="col-2 my-auto">
                                <img
                                  :src="t.image"
                                  :alt="t.title"
                                  width="100%"
                                />
                              </div>
                              <div class="col my-auto">
                                <h6 class="fw-bold mb-0">
                                  {{ t.title }}
                                </h6>
                                <small v-html="getSummaryFormatted(t)"></small>
                              </div>

                              <div class="col-2 my-auto ms-auto">
                                <input
                                  type="number"
                                  class="form-control form-control-sm"
                                  @change="updateTreatmentQuantity(key, t, $event)"
                                  :value="t.quantity"
                                />
                              </div>
                              <div class="col-auto my-auto">
                                <button
                                  class="btn btn-sm btn-outline-danger"
                                  @click="removeTreatment(key, t)"
                                >
                                  <i class="far fa-times"></i>
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <p class="mb-0">
                                  <small>{{ t.description }}</small>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <button
                    v-if="form.treatments.length > 0"
                    class="btn btn-success float-end mt-4"
                    :disabled="busy || (form.bundle == 'bundle' && form.credits < 0)"
                    @click="updateTreatmentBooking()"
                  >
                    <i class="far fa-check me-2"></i>Update Treatment Booking
                  </button>
                  <button
                    v-if="!form.treatments.length"
                    class="btn btn-outline-danger float-end mt-4"
                    :disabled="busy"
                    @click="updateTreatmentBooking()"
                  >
                    <i class="far fa-ban me-2"></i>Cancel Treatment Booking
                  </button>
                </div>
              </div>

              <!--  -->
            </form>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["booking"],
  data() {
    return {
      availableTreatments: [],
      busy: false,
      form: {
        booking_id: this.booking.id,
        bundle: this.booking.type === 'bundle' ? 'bundle' : 'no_bundle',
        treatment_booking_id: this.booking.items[0] ? this.booking.items[0].treatment_booking_id : null,
        treatments: this.getTreatmentsFromBooking(),
        credits: 10,
        client_id: this.$route.params.id,
        total: 0,
      }
    }
  },
  watch: {
    "form.bundle": {
      handler: function(val, oldVal) {
        this.recalculateTreatmentTotals();
      }
    },
  },
  methods: {
    fetchAvailableTreatments() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/treatments?retreat=" + this.booking.booking.retreat
        )
        .then(({ data }) => {
          this.availableTreatments = data;
        });
    },
    getSummaryFormatted(t) {
      if (this.form.bundle === 'no_bundle') {
        if (this.booking.booking.retreat == "malta" && t.euro_price) {
          return `${t.duration} min session / &euro;${t.euro_price.toFixed(2)}`;
        } else if (t.price) {
          return `${t.duration} min session / &pound;${t.price.toFixed(2)}`;
        } else {
          return `${t.duration} min session`;
        }
      } else {
        return `${t.duration} min session / ${t.credits} credit(s)`;
      }
    },
    getTreatmentsFromBooking() {
      let treatments = [];
      this.booking.items.forEach(item => {
        if (item.treatment && item.quantity) {
          let t = item.treatment;
          t.quantity = item.quantity;
          treatments.push(t);
        }
      });
      return treatments;
    },
    viewBooking(id) {
      this.$router.push(
        "/clients/" +
          this.$route.params.id +
          "?retreat-booking=" +
          id +
          "#retreat-bookings"
      );
    },
    addTreatment(t) {
      let newArr = [];
      let found = false;

      if (!t.quantity) {
        t.quantity = 1;
      }

      this.form.treatments.forEach((treatment) => {
        if (t.id === treatment.id) {
          newArr.push(t);
          found = true;
        } else {
          newArr.push(treatment);
        }
      });

      if (!found) {
        newArr.push(t);
      }

      this.form.treatments = newArr;
      this.recalculateTreatmentTotals();
    },
    removeTreatment(key, t) {
      this.form.treatments.splice(key, 1);
      this.recalculateTreatmentTotals();
    },
    updateTreatmentQuantity(key, t, e) {
      t.quantity = parseInt(e.target.value);

      if (t.quantity < 1) {
        this.removeTreatment(key, t);
      } else {
        this.addTreatment(t);
      }
    },
    recalculateTreatmentTotals() {
      let newCred = 0;
      let newTotal = 0;

      this.form.treatments.forEach((t) => {console.log('xxxxxxxxxxxxxxxxxx', t);
        newCred += (t.credits * t.quantity);
        newTotal += ((this.booking.booking.retreat == "malta" ? t.euro_price : t.price) * t.quantity);
      });

      this.form.credits = 10 - newCred;
      this.form.total = newTotal;
    },
    updateTreatmentBooking() {
      this.busy = true;

      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.$route.params.id +
            "/treatment-bookings",
          this.form
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", { message: data.message });
          setTimeout(() => {
            this.$emit("close");

          }, 2000);
        });
    },
    close() {
      this.$emit("close");
    },
  },
  created() {
    this.fetchAvailableTreatments();
    this.recalculateTreatmentTotals();
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format("LLL");
    },
    formatStatus: function (status) {
      if (status) {
        return status.replace(/_/g, " ");
      }
      return "unknown";
    },
  },
};
</script>

<style></style>
